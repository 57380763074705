import React from 'react'
import Layout from '../components/layout'
import Heading from '../components/page-heading'
import Paragraph from '../components/elements/paragraph'
import Button from '../components/button'

export default () => (
  <Layout>
    <Heading>NOT FOUND</Heading>
    <Paragraph>You just hit a route that doesn't exist...</Paragraph>
    <Button to="/">Go back to sandiegocpa.com</Button>
  </Layout>
)
